import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["red", "green", "blue", "hex", "colorSwatch"];

  connect() {
    console.log("Let's choose some colors!!");
  }

  updateRGB() {
    console.log("So, you changed the HEX value, didn't you?");
    var value = this.hexTarget.value;
    var new_red = parseInt(value.slice(1, 3), 16).toString(10);
    var new_green = parseInt(value.slice(3, 5), 16).toString(10);
    var new_blue = parseInt(value.slice(5, 7), 16).toString(10);
    this.redTarget.value = new_red;
    this.greenTarget.value = new_green;
    this.blueTarget.value = new_blue;
    this.updateColorSwatch();
  }

  updateHex() {
    console.log("So, you changed the HEX value, didn't you?");
    var red = parseInt(this.redTarget.value, 10).toString(16).padStart(2, "0");
    var green = parseInt(this.greenTarget.value, 10)
      .toString(16)
      .padStart(2, "0");
    var blue = parseInt(this.blueTarget.value, 10)
      .toString(16)
      .padStart(2, "0");
    this.hexTarget.value = "#" + red + green + blue;
    this.updateColorSwatch();
  }

  updateColorSwatch() {
    this.colorSwatchTarget.style.backgroundColor = this.hexTarget.value;
  }
}
