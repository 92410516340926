import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["scopeRule", "scopeRules"];

  add_rule() {
    let sourceRule = this.scopeRuleTargets.slice(-1)[0];
    let rule = sourceRule.querySelector('input[type="text"]').outerHTML;
    let ids = rule.match(/scope_rule_set\[scope_rules_attributes\]\[(\d+)\]/);
    let targetRuleId = Number(ids[1]) + 1;
    let that = this;
    let url = this.data.get("url");
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    fetch(url, {
      method: "PUT",
      body: JSON.stringify({
        scope_rule_id: targetRuleId,
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRF-Token": token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        that.scopeRulesTarget.insertAdjacentHTML("beforeend", data.html);
      });
  }

  remove_rule(event) {
    event.target.closest(".fieldset").querySelector(".remove_rule").value =
      true;
    event.target.closest(".fieldset").style.display = "none";
    // let remaining_ruless = 0;
    // this.scopeConditionsTarget
    //   .querySelectorAll(".remove_rule")
    //   .forEach((item, i) => {
    //     if (item.value == "false") {
    //       remaining_rules += 1;
    //     }
    //   });
    // if (remaining_rules == 0) {
    //   event.target
    //     .closest(".scope_conditions")
    //     .closest(".fieldset").style.display = "none";
    // }
  }
}
