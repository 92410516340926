import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["scopeCondition", "scopeConditions"];

  add_condition(event) {
    let eventTarget = event.target.closest(".fieldset");
    let sourceCondition = this.scopeConditionTargets.slice(-1)[0];
    let condition =
      sourceCondition.querySelector('input[type="text"]').outerHTML;
    let ids = condition.match(
      /\[(\d+)\]\[scope_conditions_attributes\]\[(\d+)\]/,
    );
    let targetRuleId = Number(ids[1]);
    let targetConditionId = Number(ids[2]) + 1;
    let that = this;
    let url = this.data.get("url");
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    fetch(url, {
      method: "PUT",
      body: JSON.stringify({
        scope_rule_id: targetRuleId,
        scope_condition_id: targetConditionId,
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRF-Token": token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        eventTarget.insertAdjacentHTML("afterend", data.html);
      });
  }

  remove_condition(event) {
    event.target.closest(".fieldset").querySelector(".remove_condition").value =
      true;
    event.target.closest(".fieldset").style.display = "none";
    let remaining_conditions = 0;
    this.scopeConditionsTarget
      .querySelectorAll(".remove_condition")
      .forEach((item, i) => {
        if (item.value == "false") {
          remaining_conditions += 1;
        }
      });
    if (remaining_conditions == 0) {
      event.target
        .closest(".scope_conditions")
        .closest(".fieldset").style.display = "none";
    }
  }
}
